import React from 'react';
import { IoPhonePortrait } from "react-icons/io5";
const callToActionData = {
    title: "Nie czekaj, zarezerwuj już dziś!",
    subtitle: "Spędźcie niezapomniane chwile na wodach Augustowa z naszymi wyjątkowymi łodziami motorowymi.",
    btnText: "Zadzwoń",
}

const CalltoActionEight = () => {
    return (
        <div className="rn-callto-action rn-call-to-action style-8 content-wrapper">
            <div className="container">
                <div className="row row--0 align-items-center ">
                    <div className="col-lg-12">
                        <div className="inner">
                            <div className="content text-center">
                                <h2 className="title">{callToActionData.title}</h2>
                                <h6 className="subtitle">{callToActionData.subtitle}</h6>
                                <div className="call-to-btn text-center mt--30">
                                    <a className="btn-default btn-icon" target="_blank" href="tel:+48668650693"> {callToActionData.btnText}<i className="icon"><IoPhonePortrait /></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CalltoActionEight;