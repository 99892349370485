import React, { useEffect } from 'react';
import { FiX } from 'react-icons/fi';
import Nav from './Nav';

const MobileMenu = ({ show, onClose }) => {
    useEffect(() => {
        const elements = document.querySelectorAll('.popup-mobile-menu .has-droupdown > a');
        const elementsTwo = document.querySelectorAll('.popup-mobile-menu .with-megamenu > a');

        elements.forEach(element => {
            element.addEventListener('click', function() {
                this.parentElement.querySelector('.submenu').classList.toggle("active");
                this.classList.toggle("open");
            });
        });

        elementsTwo.forEach(element => {
            element.addEventListener('click', function() {
                this.parentElement.querySelector('.rn-megamenu').classList.toggle("active");
                this.classList.toggle("open");
            });
        });

        // Clean up event listeners on component unmount
        return () => {
            elements.forEach(element => {
                element.removeEventListener('click', function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                });
            });

            elementsTwo.forEach(element => {
                element.removeEventListener('click', function() {
                    this.parentElement.querySelector('.rn-megamenu').classList.toggle("active");
                    this.classList.toggle("open");
                });
            });
        };
    }, []);

    return (
        <div className={`popup-mobile-menu ${show ? "active" : ""}`}>
            <div className="inner">
                <div className="header-top">
                    <div className="close-menu">
                        <span className="close-button" onClick={onClose}><FiX /></span>
                    </div>
                </div>
                <Nav onClose={onClose} />
            </div>
        </div>
    );
}

export default MobileMenu;
