import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const Nav = ({ onClose }) => {
    const navRef = useRef();

    useEffect(() => {
        const menuItems = navRef.current.querySelectorAll('li');

        menuItems.forEach(item => {
            item.addEventListener('click', onClose);
        });

        // Clean up event listeners on component unmount
        return () => {
            menuItems.forEach(item => {
                item.removeEventListener('click', onClose);
            });
        };
    }, [onClose]);

    return (
        <ul className="mainmenu" ref={navRef}>
            <li><Link to="/">Home</Link></li>
            <li><HashLink smooth to="/#pricing">Oferta</HashLink></li>
            <li><HashLink smooth to="/#pricing">Cennik</HashLink></li>
            <li><HashLink smooth to="/#contact">Kontakt</HashLink></li>
        </ul>
    );
}

export default Nav;