import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";

const testimonialData = [
    {
        image: 'testimonial-01',
        name: 'Piotr',
        designation: 'Facebook review', 
        description: '“Nowe łodzie, profesjonalna obsługa, idealne miejsce do wypożyczenia motorówki na dzień ze znajomymi w Augstowie.„',
       
    },
    {
        image: 'testimonial-02',
        name: 'm.pamela.91',
        designation: 'Instagram comment', 
        description: '“Bardzo dziękujemy za wspaniały weekend na Augustowiskich jeziorach. Szczerze was polecam!„',
      
    },
    {
        image: 'testimonial-03',
        name: 'Marta Rubczewska',
        designation: 'Google review',
        description: '“Wszystko bezproblemowo, eleganckie i nowoczesne motorówki. Skorzystamy jeszcze nie raz.„',
    },
]


const TestimonialOne = ({column , teamStyle}) => {
    return (
        <div className="row row--15">
            {testimonialData.map((data, index) => (
                <div className={`${column}`} key={index}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`rn-box-card ${teamStyle}`}>
                            <div className="inner">
                                <figure className="thumbnail">
                                    <img src={`./images/testimonial/${data.image}.jpg`} alt="Reviews" />
                                </figure>
                                <figcaption className="content">
                                    <p className="description">{data.description}</p>
                                    <h2 className="title">{data.name}</h2>
                                    <h6 className="subtitle">{data.designation}</h6>
                                </figcaption>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}

export default TestimonialOne;
