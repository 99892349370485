import React, { Component } from "react";

class GoogleMapStyle extends Component {
  render() {
    return (
      // Important! Always set the container height explicitly

      <div className="google-map-style-1">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1469.0380409265056!2d22.983458809948498!3d53.849903987492695!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46e051027303b59f%3A0x67c7958a1c8dbb4d!2sMOS%20w%20AUGUSTOWIE!5e0!3m2!1sen!2spl!4v1718703131405!5m2!1sen!2spl"
          width="100%"
          height="100%"
          style={{ borderRadius: "10px", position: "relative", border: "0" }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    );
  }
}

export default GoogleMapStyle;
