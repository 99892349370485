import React from 'react'
import SEO from "../common/SEO";
import HeaderTwo from '../common/header/HeaderTwo';
import FooterThree from '../common/footer/FooterThree';
import { FiFacebook, FiInstagram } from "react-icons/fi";
import { HashLink } from 'react-router-hash-link';

import Slider from "react-slick";
import { BannerActivation } from "../utils/script";
import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import PortfolioOne from "../elements/portfolio/PortfolioOne";
import CalltoActionTwo from '../elements/calltoaction/CalltoActionTwo';
import CalltoActionTen from '../elements/calltoaction/CalltoActionTen';

import TestimonialOne from "../elements/testimonial/TestimonialOne";
import ContactOne from '../elements/contact/ContactOne';




const BannerData = [
    {
        image: "/images/bg/bg-image-15.jpg",
        title: "Black Wave",
        description: "Wypożyczalnia ekskluzywnych łodzi motorowych TEXAS, które zadowolą nawet najbardziej wymagających miłośników wodnych przygód."
    },
    {
        image: "/images/bg/6.jpeg",
        title: "Black Wave",
        description: "Wypożyczalnia ekskluzywnych łodzi motorowych TEXAS, które zadowolą nawet najbardziej wymagających miłośników wodnych przygód."
    },
    {
        image: "/images/bg/bg-image-6.jpg",
        title: "Black Wave",
        description: "Wypożyczalnia ekskluzywnych łodzi motorowych TEXAS, które zadowolą nawet najbardziej wymagających miłośników wodnych przygód."
    },
]


const DigitalAgency = () => {
    return (
        <>
            <SEO title="BlackWave" />
            <main className="page-wrapper">
                
                <HeaderTwo btnStyle="btn-small" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <Slider className="slider-area slider-style-4 slider-dot rn-slick-dot rn-slick-arrow" {...BannerActivation}>
                    {BannerData.map((data, index) => (
                        <div key={index} className="single-slide">
                            <div className="height-950 bg-overlay bg_image" style={{backgroundImage: `url(${process.env.PUBLIC_URL} ${data.image})`}}>
                                <div className="container">
                                    <div className="row row--30 align-items-center">
                                        <div className="order-2 order-lg-1 col-lg-7">
                                            <div className="inner text-start">
                                                <h1 className="title" dangerouslySetInnerHTML={{__html: data.title}}></h1>
                                                <p className="description" dangerouslySetInnerHTML={{__html: data.description}}></p>
                                                <div className="button-group mt--30">
                                                <HashLink className="btn-default" smooth to="/#contact">Skontaktuj się</HashLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
                <div className="row mt--30">
                                <div className="col-lg-12">
                                    <ul className="social-icon social-default with-gradient">
                                        <li><a target="_blank" href="https://www.facebook.com/profile.php?id=61561259094203"><FiFacebook /></a></li>
                                        <li><a target="_blank" href="https://www.instagram.com/blackwave_augustow?igsh=bjFjcXV4aHJnYXh3"><FiInstagram /></a></li>
                                    </ul>
                                </div>
                            </div>
                {/* End Slider Area  */}




                <Separator />
                <div id="pricing" className="rwt-portfolio-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = ""
                                        title = "Wybierz łódź"
                                        description = "Spędźcie niezapomniane chwile na wodach Augustowa z naszymi wyjątkowymi łodziami motorowymi.<br> Oferujemy czarter z doświadczonym sternikiem."
                                    />
                            </div>
                        </div>
                        <PortfolioOne Column="col-lg-6 col-md-6 col-sm-6 col-12 mt--30 portfolio no-overlay"  />
                    </div>
                    <CalltoActionTwo />
                </div>


                <Separator />            
                {/* Start Testimonial Area  */}
                <div className="rwt-testimonial-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        title = "Opinie naszych klientów"
                                        description = "Sprawdź ostatnie rekomendacje od naszych gości."
                                    />
                            </div>
                        </div>
                        <TestimonialOne column="col-lg-4 col-md-6 col-sm-6 col-12 mt--30" teamStyle="card-style-default testimonial-style-one style-two" />
                    </div>
                </div>
                {/* End Testimonial Area  */}
                <div className="rwt-contact-area rn-section-gap">
                                    <div className="rwt-testimonial-area rn-section-gap">
                         <CalltoActionTen />
                </div>
                    <div id="contact" className="container">
                    <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = ""
                                        title = "Kontakt"
                                        description = "Zadzwoń aby zapytać o termin."
                                    />
                            </div>
                         <ContactOne />
                    </div>
                </div>
                <FooterThree />
            </main>
        </>
    )
}
export default DigitalAgency;